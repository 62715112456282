import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { UserValidators } from '../user.util';

@Component({
  selector: 'zlo-forgot-password',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  @Input()
  set pending(isPending: boolean) {
    if (isPending) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Output()
  submitted = new EventEmitter<string>();

  form: UntypedFormGroup = new UntypedFormGroup(
    {
      email: new UntypedFormControl('', UserValidators.requiredEmail)
    },
    { updateOn: 'submit' }
  );

  submit() {
    if (this.form.valid) {
      this.submitted.emit(this.form.get('email').value.trim());
    }
  }
}
