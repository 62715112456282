import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import { Actions, createEffect } from '@ngrx/effects';
import { switchMap, map, filter, withLatestFrom } from 'rxjs/operators';
import * as layout from '../actions/layout.actions';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../../core/reducers';
import * as auth from '../../../auth/actions/auth.actions';

@Injectable({ providedIn: 'root' })
export class CoreEffects {
  static ACTIVITY_TIMEOUT = 5 * 1000 * 60;
  static SESSION_TIMEOUT = 10 * 1000 * 60;

  whitelistedActions = [
    'NOOP',
    '[Admin] Fetch Distributor Report',
    '[Admin] Fetch Distributor Report Success',
    '[Layout] Set Session State Idle'
  ];

  extendIdleTimeout$ = createEffect(() =>
    this.actions$.pipe(
      // listening to all actions, especially the SetSessionStateActive Action
      filter((action) => !this.whitelistedActions.includes(action.type)),
      switchMap(() => timer(CoreEffects.ACTIVITY_TIMEOUT)),
      withLatestFrom(this.store.pipe(select(fromRoot.isSessionActive))),
      map(([_, active]) =>
        active ? new layout.SetSessionStateIdle() : auth.Noop()
      ),
      filter((action) => !!action)
    )
  );

  extendSessionTimeout$ = createEffect(() =>
    this.actions$.pipe(
      // listening to all actions, especially the SetSessionStateActive Action
      filter((action) => !this.whitelistedActions.includes(action.type)),
      switchMap(() => timer(CoreEffects.SESSION_TIMEOUT)),
      withLatestFrom(this.store.pipe(select(fromRoot.isSessionIdle))),
      switchMap(([_, idle]) =>
        idle
          ? [new layout.SetSessionStateExpired(), auth.ExpireSession()]
          : [auth.Noop()]
      ),
      filter((action) => !!action)
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>
  ) {}
}
