import { Action } from '@ngrx/store';

export enum LayoutActionTypes {
  ToggleArchivedFilter = '[Layout] Toggle Archive Filter',
  ToggleDraftFilter = '[Layout] Toggle Draft Filter',
  ToggleScheduledFilter = '[Layout] Toggle Scheduled Filter',
  ToggleSentFilter = '[Layout] Toggle Sent Filter',
  SetSessionStateUninitialized = '[Layout] Set Session State Uninitialized',
  SetSessionStateActive = '[Layout] Set Session State Active',
  SetSessionStateIdle = '[Layout] Set Session State Idle',
  SetSessionStateExpired = '[Layout] Set Session State Expired'
}

export class ToggleArchivedFilter implements Action {
  readonly type = LayoutActionTypes.ToggleArchivedFilter;
}

export class ToggleDraftFilter implements Action {
  readonly type = LayoutActionTypes.ToggleDraftFilter;
}

export class ToggleScheduledFilter implements Action {
  readonly type = LayoutActionTypes.ToggleScheduledFilter;
}

export class ToggleSentFilter implements Action {
  readonly type = LayoutActionTypes.ToggleSentFilter;
}

export class SetSessionStateUninitialized implements Action {
  readonly type = LayoutActionTypes.SetSessionStateUninitialized;
}

export class SetSessionStateActive implements Action {
  readonly type = LayoutActionTypes.SetSessionStateActive;
}

export class SetSessionStateIdle implements Action {
  readonly type = LayoutActionTypes.SetSessionStateIdle;
}

export class SetSessionStateExpired implements Action {
  readonly type = LayoutActionTypes.SetSessionStateExpired;
}

export type LayoutActions =
  | ToggleArchivedFilter
  | ToggleDraftFilter
  | ToggleScheduledFilter
  | ToggleSentFilter
  | SetSessionStateUninitialized
  | SetSessionStateActive
  | SetSessionStateIdle
  | SetSessionStateExpired;
