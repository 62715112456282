import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Authenticate, PasswordChange } from 'src/types';
import * as fromAuth from '../reducers';
import * as Auth from '../actions/auth.actions';
import * as fromCore from '../../core/reducers';

@Component({
  selector: 'zlo-login-page',
  templateUrl: './login-page.component.html'
})
export class LoginPageComponent {
  pending$ = this.store.pipe(select(fromAuth.getLoginPagePending));
  routerState$ = this.store.pipe(select(fromCore.getRouterState));

  constructor(private store: Store<fromAuth.State>) {}

  onSubmit($event: Authenticate | PasswordChange | string, url: string) {
    if (url.endsWith('/auth/login')) {
      return this.store.dispatch(Auth.Login($event as Authenticate));
    }

    if (url.endsWith('/auth/forgot')) {
      return this.store.dispatch(Auth.Forgot({ payload: $event as string }));
    }
  }
}
