import { createReducer, on, union } from '@ngrx/store';
import { ZelosPermissions, Channel, OrganizationSettings } from 'src/types';
import * as AuthActions from './../actions/auth.actions';
import { User } from 'src/types';

import * as LegacySettingsActions from 'src/app/adminPortal/profile/actions/legacy.settings.actions';
import * as SettingsActions from 'src/app/adminPortal/profile/actions/settings.actions';
import * as LegacyOrganizationActions from 'src/app/legacy.organizations.actions';
import * as OrganizationActions from 'src/app/organization.actions';
import { PERMISSIONS_KEY, USER_KEY } from '../user.util';
import { keyBy } from 'src/app/adminPortal/shared/util';

export interface State {
  loggedIn: boolean;
  user: User | null;
  permissions: {
    zelos: ZelosPermissions;
  };
}

export const initialState: State = {
  loggedIn: false,
  user: null,
  permissions: {
    zelos: { canEdit: false, canDuplicate: false, canSend: false }
  }
};

const SettingsActionsUnion = union({ ...SettingsActions });
const OrganizationActionsUnion = union({ ...OrganizationActions });
const LegacyOrganizationActionsUnion = union({ ...LegacyOrganizationActions });

type Actions =
  | LegacySettingsActions.LegacySettingsActions
  | typeof SettingsActionsUnion
  | typeof OrganizationActionsUnion
  | typeof LegacyOrganizationActionsUnion;

const authReducer = createReducer(
  initialState,
  on(SettingsActions.loadAuthenticatedDomainsSuccess, (state, domains) => {
    if (!state.user) return state;

    const { type, ...rest } = domains;
    return {
      ...state,
      user: {
        ...state.user,
        organization: {
          ...state.user.organization,
          settings: {
            ...state.user.organization.settings,
            whitelabel: {
              ...state.user.organization.settings?.whitelabel,
              domains: rest
            }
          }
        }
      }
    };
  }),
  on(SettingsActions.addAuthenticatedDomainSuccess, (state, { domain }) => {
    return {
      ...state,
      user: {
        ...state.user,
        organization: {
          ...state.user.organization,
          settings: {
            ...state.user.organization.settings,
            whitelabel: {
              ...state.user.organization.settings.whitelabel,
              domains: {
                ...state.user.organization.settings.whitelabel.domains,
                [domain.domain]: domain
              }
            }
          }
        }
      }
    };
  }),
  on(SettingsActions.addAuthenticatedDomain, (state, { domain }) => {
    return {
      ...state,
      user: {
        ...state.user,
        organization: {
          ...state.user.organization,
          settings: {
            ...state.user.organization.settings,
            whitelabel: {
              ...state.user.organization.settings.whitelabel,
              domains: {
                ...state.user.organization.settings.whitelabel.domains,
                [domain]: { domain }
              }
            }
          }
        }
      }
    };
  }),
  on(SettingsActions.deleteAuthenticatedDomain, (state, { domain }) => {
    delete state.user.organization.settings.whitelabel.domains[domain];
    return {
      ...state,
      user: {
        ...state.user,
        organization: {
          ...state.user.organization,
          settings: {
            ...state.user.organization.settings,
            whitelabel: {
              ...state.user.organization.settings.whitelabel,
              domains: {
                ...state.user.organization.settings.whitelabel.domains
              }
            }
          }
        }
      }
    };
  }),
  on(SettingsActions.verifyAuthenticatedDomainsSuccess, (state, payload) => {
    return {
      ...state,
      user: {
        ...state.user,
        organization: {
          ...state.user.organization,
          settings: {
            ...state.user.organization.settings,
            whitelabel: {
              ...state.user.organization.settings.whitelabel,
              domains: {
                ...state.user.organization.settings.whitelabel.domains,
                [payload.domain]: {
                  ...payload
                }
              }
            }
          }
        }
      }
    };
  }),

  on(
    OrganizationActions.loadOrganizationSendersSuccess,
    (state, { senders }) => {
      // successful filter payload has new id
      const newState = {
        ...state,
        user: {
          ...state.user,
          organization: {
            ...state.user.organization,
            senders: {
              ...state.user.organization.senders,
              ...senders
            }
          }
        }
      };

      return newState;
    }
  ),
  on(OrganizationActions.addOrganizationSenderSuccess, (state, { sender }) => {
    // successful filter payload has new id
    const newState = {
      ...state,
      user: {
        ...state.user,
        organization: {
          ...state.user.organization,
          senders: {
            ...state.user.organization.senders,
            ...sender
          }
        }
      }
    };

    return newState;
  }),
  on(
    OrganizationActions.updateOrganizationSenderSuccess,
    (state, { sender }) => {
      // successful filter payload has new id
      const newState = {
        ...state,
        user: {
          ...state.user,
          organization: {
            ...state.user.organization,
            senders: {
              ...state.user.organization.senders,
              ...sender
            }
          }
        }
      };

      return newState;
    }
  ),
  on(OrganizationActions.deleteOrganizationSender, (state, { senderId }) => {
    // successful filter payload has new id
    const senders = state.user.organization.senders;
    delete senders[senderId];

    const newState = {
      ...state,
      user: {
        ...state.user,
        organization: {
          ...state.user.organization,
          senders: {
            ...senders
          }
        }
      }
    };

    return newState;
  }),
  on(AuthActions.LoginSuccess, (state, { payload }) => {
    return {
      ...state,
      loggedIn: true,
      user: {
        ...payload.user,
        organization: {
          ...payload.user.organization,
          channels: {
            ...keyBy<Channel>(
              (payload.user.organization.channels as unknown) as Channel[],
              'channelName'
            )
          }
        }
      },
      permissions: payload.permissions
    };
  }),
  on(AuthActions.Logout, () => {
    return initialState;
  }),
  on(AuthActions.LoginRedirect, () => {
    return initialState;
  }),
  on(AuthActions.Init, (state) => {
    let user = null;
    let permissions = initialState.permissions;
    const userKey = localStorage.getItem(USER_KEY);

    if (userKey) {
      try {
        user = JSON.parse(userKey);
      } catch {
        console.log(`unable to parse ${userKey} from local storage`);
      }

      if (Array.isArray(user?.organization?.channels)) {
        user.organization.channels = keyBy<Channel>(
          user.organization.channels,
          'channelName'
        );
      } else {
        console.log('user.organization.channels was not an array');
      }
    }

    try {
      permissions = JSON.parse(localStorage.getItem(PERMISSIONS_KEY));
    } catch {
      permissions = initialState.permissions;
    }

    return {
      ...state,
      user,
      permissions
    };
  }),
  on(
    LegacyOrganizationActions.UpdateOrganizationSuccess,
    (
      state,
      { language, name, organizationNumber, verified, tier, employeeCount }
    ) => {
      const { user } = state;

      if (!user) return state;

      user.organization = {
        ...user.organization,
        language,
        name,
        organizationNumber,
        verified,
        tier,
        employeeCount
      };

      if (['econa'].includes(user.organization.language)) {
        user.organization.language = 'no';
      }

      localStorage.setItem(USER_KEY, JSON.stringify(user));

      return {
        ...state,
        user: { ...user }
      };
    }
  ),
  on(SettingsActions.saveRosterColumns, (state, { columns }) => {
    const newState = structuredClone(state);
    newState.user.prefs.rosterColumns = columns;
    localStorage.setItem(USER_KEY, JSON.stringify(newState.user));
    return newState;
  })
);

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case LegacySettingsActions.LegacySettingsActionTypes
      .UpdateCurrentUserSuccess: {
      const user = action;
      return {
        ...state,
        user: {
          ...state.user,
          ...user
        },
        permissions: user.permissions
      };
    }
    case LegacySettingsActions.LegacySettingsActionTypes
      .UpdateIntegrationSuccess: {
      const { user } = state;
      const { payload: channels } = action;

      if (!user) {
        return state;
      }

      user.organization.channels = {
        ...user.organization.channels,
        ...keyBy<Channel>(channels, 'channelName')
      };

      user.organization = { ...user.organization };

      localStorage.setItem(USER_KEY, JSON.stringify(user));

      return {
        ...state,
        user: { ...user }
      };
    }

    case LegacySettingsActions.LegacySettingsActionTypes
      .UpdateOrganizationPreferencesSuccess: {
      const { user } = state;
      const { organizationPreferences } = action;

      if (!user) {
        return state;
      }

      user.organization.settings = {
        ...user.organization.settings,
        ...(organizationPreferences as OrganizationSettings)
      };

      user.organization = { ...user.organization };

      localStorage.setItem(USER_KEY, JSON.stringify(user));

      return {
        ...state,
        user: { ...user }
      };
    }

    case LegacySettingsActions.LegacySettingsActionTypes
      .LoadOrganizationWhitelabelSuccess: {
      const { user } = state;
      const { whitelabel } = action;

      if (!user) {
        return state;
      }

      return {
        ...state,
        user: {
          ...user,
          organization: {
            ...user.organization,
            settings: {
              ...user.organization.settings,
              whitelabel: {
                ...user?.organization?.settings?.whitelabel,
                ...whitelabel
              }
            }
          }
        }
      };
    }

    case LegacySettingsActions.LegacySettingsActionTypes
      .CreateIntegrationCredentialsSuccess: {
      const { user } = state;
      const channels = action?.channels;

      user.organization.channels = {
        ...user.organization.channels,
        ...(channels.length
          ? keyBy<Channel>(channels, 'channelName')
          : {
              [((channels as unknown) as Channel).channelName]: channels
            })
      };

      user.organization = { ...user.organization };

      localStorage.setItem(USER_KEY, JSON.stringify(user));

      return {
        ...state,
        user: { ...user }
      };
    }

    default: {
      return authReducer(state, action);
    }
  }
}

export const getLoggedIn = (state: State) => state.loggedIn;
export const getUser = (state: State) => state.user;
export const getPermissions = (state: State) => state.permissions;
