import { DOCUMENT } from '@angular/common';
import { updateCurrentUserSuccess } from '../../adminPortal/profile/actions/settings.actions';
import { User, ZelosPermissions } from 'src/types';
import { Inject, Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { tap, map, exhaustMap, catchError } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import * as AuthActions from '../actions/auth.actions';
import { Go } from '../../core/actions/router.actions';
import { PasswordChange } from 'src/types';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { TOKEN_KEY, USER_KEY, PERMISSIONS_KEY, ENV_KEY } from '../user.util';
import { SegmentService } from '../../../app/shared/services/segment.service';

const persistLoginArtifacts = (
  token: string,
  permissions: {
    zelos: ZelosPermissions;
  },
  dbHostname?: string
) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(PERMISSIONS_KEY, JSON.stringify(permissions));
  if (dbHostname) localStorage.setItem(ENV_KEY, dbHostname);
};

@Injectable({ providedIn: 'root' })
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private segment: SegmentService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.Login),
      exhaustMap((auth) =>
        this.authService.login(auth).pipe(
          map(({ token, user, employees, dbHostname, redirectUrl }) => {
            const permissions = user.permissions;

            persistLoginArtifacts(token, permissions, dbHostname);

            if (redirectUrl && redirectUrl !== 'qa') {
              this.document.location.href = redirectUrl;
              return AuthActions.Logout();
            }

            return AuthActions.LoginSuccess({
              payload: {
                user,
                employees,
                permissions
              }
            });
          }),
          catchError((error) => {
            return of(AuthActions.LoginFailure(error));
          })
        )
      )
    )
  );
  //
  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.Forgot),
      map((action) => action.payload),
      exhaustMap((email: string) =>
        this.authService.forgot(email).pipe(
          map(() => AuthActions.ForgotSuccess()),
          tap(() => {
            this.segment.track('Forgot Password');
          }),
          catchError((error) => {
            return of(AuthActions.ForgotFailure(error));
          })
        )
      )
    )
  );

  forgotSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.ForgotSuccess),
      map(() => {
        this.toastr.show('', 'Email sent with the new Password', {
          toastClass: 'zelo-toast zelo-toast-success'
        });
        return AuthActions.LoginRedirect();
      })
    )
  );

  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.Change),
      exhaustMap((creds: PasswordChange) =>
        this.authService.change(creds).pipe(
          map(() => AuthActions.ChangeSuccess()),
          catchError((error) => {
            return of(AuthActions.ChangeFailure(error));
          })
        )
      )
    )
  );

  changePasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.ChangeSuccess),
      tap(() => {
        this.segment.track('Password Changed');
        this.toastr.show('', 'The password is now updated', {
          toastClass: 'zelo-toast zelo-toast-success'
        });
      }),
      map(() => new Go({ path: ['/'] }))
    )
  );

  // Completely working loginSuccess$
  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.LoginSuccess),
      tap((action) => {
        localStorage.setItem(USER_KEY, JSON.stringify(action.payload.user));
        const userObject = action.payload.user;
        const language =
          userObject.settings?.preferredLanguage ||
          userObject.organization.language;
        this.translate.use(language);
        this.segment.track('Signed In');
      }),
      map(() => new Go({ path: ['/zelo'] }))
    )
  );

  updateCurrentUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateCurrentUserSuccess),
        tap((user) => {
          const permissions = user.permissions;

          const storedUser = JSON.parse(localStorage.getItem(USER_KEY));

          localStorage.setItem(
            USER_KEY,
            JSON.stringify({
              ...storedUser,
              ...user,
              settings: { ...user.settings }
            })
          );
          localStorage.setItem(PERMISSIONS_KEY, JSON.stringify(permissions));
          const language =
            user.settings?.preferredLanguage || user.organization.language;
          this.translate.use(language);

          this.toastr.show('', 'Recipient is now updated.', {
            toastClass: 'zelo-toast zelo-toast-success'
          });
        })
      ),
    { dispatch: false }
  );

  init$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.Init),
        tap(() => {
          const user: User = JSON.parse(localStorage.getItem(USER_KEY) || '{}');

          if (user && user.organization) {
            const language =
              user.settings?.preferredLanguage ||
              user.organization.language ||
              'spama';
            this.translate.use(language);
          }

          return user && user.organization;
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.Logout),
        map(() => {
          this.segment.track('Signed Out');
          return this.authService.logout();
        })
      ),
    { dispatch: false }
  );

  loginRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.LoginRedirect, AuthActions.Logout),
        map(() => {
          return this.authService.logout();
        })
      ),
    { dispatch: false }
  );

  expireSession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.ExpireSession),
        map(() => {
          return this.authService.logout('/auth/expired-session');
        })
      ),
    { dispatch: false }
  );

  overviewLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.OverviewLink),
      exhaustMap((action) => {
        return this.authService.generateOverviewLink(action.payload).pipe(
          tap(() => {
            this.segment.track('Overview Link Requested');
          }),
          map(() => AuthActions.OverviewLinkSuccess()),
          catchError((error) => {
            return of(AuthActions.OverviewLinkFailure(error));
          })
        );
      })
    )
  );

  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.Register),
      exhaustMap((action) =>
        this.authService.register(action).pipe(
          map(({ user, token, redirectUrl }) => {
            if (user && token) {
              persistLoginArtifacts(token, user.permissions);

              if (redirectUrl) {
                if (redirectUrl !== 'qa') {
                  this.segment.identifySegmentUser(user, {
                    isSignupFlow: true,
                    googleAnalytics: true
                  });

                  this.segment.trackAnonymous(
                    'Signed Up',
                    {
                      tier: user.organization.tier
                    },
                    {
                      googleAnalytics: true
                    }
                  );
                }

                this.document.location.href = redirectUrl;
                return AuthActions.Logout();
              }

              this.segment.trackAnonymous(
                'Order Completed',
                {
                  tier: user.organization.tier
                },
                {
                  googleAnalytics: true
                }
              );

              return AuthActions.LoginSuccess({
                payload: {
                  user,
                  employees: [],
                  permissions: user.permissions
                }
              });
            }
          }),
          catchError((error) => {
            return of(AuthActions.LoginFailure(error));
          })
        )
      )
    )
  );

  failures$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AuthActions.LoginFailure,
          AuthActions.ForgotFailure,
          AuthActions.ChangeFailure,
          AuthActions.OverviewLinkFailure
        ),
        tap((error) => {
          const toastOptions = {
            toastClass: 'zelo-toast zelo-toast-alert',
            timeOut: 10000,
            enableHtml: true
          };

          const errorCodes = {
            missingConnection: 0,
            serverError: 500
          };

          const message = {
            title: '',
            body: ''
          };

          switch (error.status) {
            case errorCodes.missingConnection: {
              message.body =
                'Please ensure that you are connected to the internet and try again';
              message.title = 'Connection to the internet lost';
              break;
            }

            case errorCodes.serverError: {
              message.body =
                'Something unexpected went wrong. Please try again';
              message.title = 'Server error';
              break;
            }

            default: {
              if (error.error && error.error.message) {
                const msg = error.error.message;

                if (['permissions', 'no_user', 'credentials'].includes(msg)) {
                  message.body = this.translate.instant(`auth.errors.${msg}`);
                } else {
                  message.body = error.error.message;
                  message.title = error.error.error;
                }
              } else {
                message.body =
                  'If you keep getting this error please take a screenshot of this message and send it to an administrator. ' +
                  JSON.stringify(error);
                message.title = 'Unexpected error';
              }
              break;
            }
          }

          return this.toastr.show(message.body, message.title, toastOptions);
        })
      ),
    { dispatch: false }
  );
}
