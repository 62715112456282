<zlo-background>
  <div class="flex column align-center">
    <div class="logo-container">
      <zlo-icon name="zelo-logo" width="200px" height="200px"></zlo-icon>
    </div>
    <section
      class="card auto-width flex column align-center margin-tl shadow-2 padding-xl"
    >
      <div class="flex column align-center">
        <h5>
          {{ 'auth.expired.title' | translate }}
          {{ 'auth.expired.subtitle' | translate }}
        </h5>
      </div>
      <div class="">
        &nbsp;
      </div>

      <div class="">
        <div>
          {{
            'auth.expired.message1' | translate: { number: getSessionTimeout() }
          }}
        </div>
        <div>{{ 'auth.expired.message2' | translate }}</div>
      </div>
      <div class="">
        &nbsp;
      </div>
      <div class="button-padding">
        <button class="button" routerLink="/auth/login">
          {{ 'auth.expired.buttonText' | translate | uppercase }}
        </button>
      </div>
    </section>
  </div>
</zlo-background>
