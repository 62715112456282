import * as AuthActions from '../actions/auth.actions';
import { createReducer, on, Action } from '@ngrx/store';

export interface State {
  pending: boolean;
}

export const initialState: State = {
  pending: false
};

const loginPageReducer = createReducer(
  initialState,
  on(AuthActions.Login, (state) => ({
    ...state,
    pending: true
  })),
  on(AuthActions.LoginSuccess, AuthActions.LoginFailure, (state) => ({
    ...state,
    pending: false
  })),
  on(AuthActions.Logout, () => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return loginPageReducer(state, action);
}

export const getPending = (state: State) => state.pending;
