import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { TOKEN_KEY } from '../user.util';
import { ToastrService } from 'ngx-toastr';

let refreshingInProgress: boolean;
let accessTokenSubject = new BehaviorSubject<string>(null);
let accessTokenTimestamp = Date.now();

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  private add(request: HttpRequest<any>, token: string): HttpRequest<any> {
    const auth = { Authorization: `Bearer ${token}` };
    return token ? request.clone({ setHeaders: auth }) : request;
  }

  private sessionExpired(): void {
    this.toastr.warning('Please log in again.', 'Session expired');
    this.authService.logout();
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const accessToken = localStorage.getItem(TOKEN_KEY);

    // add access token header to outgoing request
    return next.handle(this.add(req, accessToken)).pipe(
      tap((res: any) => {
        if (res.status === 200) {
          // with ongoing successful user activity ...
          if (accessTokenTimestamp + 1000 * 60 * 4 < Date.now()) {
            // get a new Access Token every 4 minutes
            accessTokenTimestamp = Date.now();
            //console.log('pre-emptively fetching new access token');
            this.authService.accessToken().subscribe();
          }
        }
      }),
      catchError((err) => {
        // an error occurred - we'll handle HTTP 401 Unauthorized
        if (err.error?.message === 'Expired token') {
          if (refreshingInProgress) {
            // wait for refreshed token, then re-do request
            return accessTokenSubject.pipe(
              filter((token) => token !== null),
              switchMap((token) => {
                // console.log('re-requesting ' + req.url);
                return next.handle(this.add(req, token));
              })
            );
          }

          refreshingInProgress = true;
          //console.log('set refreshingInProgress true');
          accessTokenSubject.next(null);

          return this.authService.accessToken().pipe(
            switchMap((res) => {
              refreshingInProgress = false;
              //console.log('set refreshingInProgress false');
              accessTokenSubject.next(res.token);
              //console.log('re-requesting ' + req.url);
              return next.handle(this.add(req, res.token));
            }),
            catchError((err) => {
              if (err.error?.message === 'Bad Refresh Token') {
                //console.log('token interceptor: bad refresh token');
                this.sessionExpired();
                return err;
              }

              if (err.error?.message === 'Missing authentication') {
                this.sessionExpired();
                return err;
              }

              //console.log('token interceptor: caught unexpected error');
              return next.handle(req);
            })
          );
        }

        if (
          ['Bad Refresh Token', 'Missing authentication'].includes(
            err.error?.message
          )
        ) {
          this.sessionExpired();
          return err;
        }

        //console.log('token interceptor: caught unexpected error');
        return next.handle(req);
      })
    );
  }
}
