import {
  ActionReducerMap,
  createSelector,
  createFeatureSelector,
  ActionReducer,
  MetaReducer,
  MemoizedSelector
} from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';
import * as fromAuth from '../../auth/reducers';
import { RouterStateSerializer } from '@ngrx/router-store';
import { RouterStateSnapshot, Params } from '@angular/router';

/**
 * storeFreeze prevents state from being mutated. When mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the state.
 */
// import { storeFreeze } from 'ngrx-store-freeze';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

import * as fromAdminLayout from '../../adminPortal/core/reducers/layout.reducer';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  layout: fromAdminLayout.State;
  router: fromRouter.RouterReducerState<RouterStateUrl>;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<State> = {
  layout: fromAdminLayout.reducer,
  router: fromRouter.routerReducer
};

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: any): State => {
    return reducer(state, action);
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [logger /* , storeFreeze */]
  : [];

/**
 * Layout Reducers
 */
export const getLayoutState = createFeatureSelector<fromAdminLayout.State>(
  'layout'
);

export const getShowSidenav = createSelector(
  getLayoutState,
  fromAdminLayout.getShowSidenav
);

export const isSessionUninitialized = createSelector(
  getLayoutState,
  fromAdminLayout.isSessionUninitialized
);

export const isSessionActive = createSelector(
  getLayoutState,
  fromAdminLayout.isSessionActive
);

export const isSessionIdle = createSelector(
  getLayoutState,
  fromAdminLayout.isSessionIdle
);

export const isSessionExpired = createSelector(
  getLayoutState,
  fromAdminLayout.isSessionExpired
);

/**
 * Router selectors
 */

export const getRouter: MemoizedSelector<
  object,
  { state: RouterStateUrl }
> = createFeatureSelector('router');

export const getRouterState = createSelector(
  getRouter,
  (router) => router?.state
);

/**
 * The RouterStateSerializer takes the current RouterStateSnapshot
 * and returns any pertinent information needed. The snapshot contains
 * all information about the state of the router at the given point in time.
 * The entire snapshot is complex and not always needed. In this case, you only
 * need the URL and query parameters from the snapshot in the store. Other items could be
 * returned such as route parameters and static route data.
 */

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

@Injectable()
export class CustomRouterStateSerializer
  implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams }
    } = routerState;
    const { params } = route;

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return { url, params, queryParams };
  }
}

/**
 * Other selectors
 */

export const selectAuthState = createFeatureSelector<fromAuth.AuthState>(
  'auth'
);

export const selectAuthStatusState = createSelector(
  selectAuthState,
  (state: fromAuth.AuthState) => state.status
);

export const getUserPermissions = createSelector(
  selectAuthStatusState,
  (state) => {
    return state.permissions;
  }
);

export const canEdit = createSelector(getUserPermissions, (userPermissions) => {
  return userPermissions && userPermissions.zelos.canEdit !== undefined
    ? userPermissions.zelos.canEdit
    : true;
});
