export default class Util {
  static lastNameWithFirstInitial(firstname: string, lastname: string) {
    let name =
      lastname && lastname.trim()
        ? lastname.trim()
        : firstname && firstname.trim()
        ? firstname
        : '';

    const fname =
      lastname && lastname.trim()
        ? firstname && firstname.trim()
          ? firstname.trim()
          : ''
        : '';

    let initial = '';

    if (
      fname &&
      fname[0].toUpperCase() >= 'A' &&
      fname[0].toUpperCase() <= 'Z'
    ) {
      initial = fname[0].toUpperCase() + '. ';
    }

    name = name
      .replace(/[`~!@#$%^&*()_|+=?;:",<>\{\}\[\]\\\/]/gi, '')
      .toUpperCase();

    return initial + name;
  }

  static convertToEmbeddedLink(url: string) {
    const service = Util.getVideoService(url);

    return service ? Util.videoLinkBuilder(url, service) : '';
  }

  static getVideoId(link: string, service: 'youtube' | 'vimeo') {
    const matches = link.match(
      {
        youtube:
          '^(?:https?:)?//[^/]*(?:youtube(?:-nocookie)?.com|youtu.be).*[=/]([-\\w]{11})(?:\\?|=|&|$)',
        vimeo: /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i
      }[service]
    );

    return (matches && matches[1]) || null;
  }

  static videoLinkBuilder(link: string, service: 'youtube' | 'vimeo') {
    const videoId = Util.getVideoId(link, service);

    const domain = {
      youtube: 'https://www.youtube.com/embed/',
      vimeo: 'https://player.vimeo.com/video/'
    };

    return videoId ? domain[service] + videoId : '';
  }

  static getVideoService(url: string): 'youtube' | 'vimeo' {
    const urlParts = new Set(url.split(/\/|\./g));

    if (urlParts.has('youtube') || urlParts.has('youtu')) {
      return 'youtube';
    } else if (urlParts.has('vimeo')) {
      return 'vimeo';
    } else {
      return null;
    }
  }

  static getYoutubeId(link: string): string {
    const match = link.match(
      /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/
    );

    return match[1];
  }

  static secondsToHourFormat(seconds: number) {
    seconds = Number(seconds);
    const hours = Math.floor(seconds / 3600);
    const months = Math.floor((seconds % 3600) / 60);
    const sec = Math.floor((seconds % 3600) % 60);

    const hourDisplay = hours > 0 ? hours + ':' : '00:';
    const monthDisplay = months > 0 ? months + ':' : '00:';
    const secondDisplay = sec > 0 ? sec : '00';
    return hourDisplay + monthDisplay + secondDisplay;
  }

  static replaceString(
    stringToReplace: string,
    replaceSelector: string[],
    replacedString: string
  ) {
    replaceSelector.map((x) => {
      stringToReplace = stringToReplace.replace(x, replacedString);
    });
    return stringToReplace;
  }
}

export const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const CREDITCARD_PATTERN = /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/;
