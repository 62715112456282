export const environment = {
  production: true,
  api: 'https://api.experimental.zelo.no',
  client: 'https://experimental.zelo.no',
  worker: true,
  mapBoxToken:
    'pk.eyJ1IjoiemVsb2RldiIsImEiOiJjazVyeGJxaHcwMHl0M2xyczVtbnByaW9hIn0.COtrgVE-U-5N0JM67qDZjA',
  gApiKey: 'AIzaSyDMoiEYAQGC6YlwKWwEyen2zOZq8TRR_v8',
  intercomVerificationKey: 'rQItr3P2Ka3CB5fqiGJr5NM9OFRVxO7Z-gVtWSKi'
};
