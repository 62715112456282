import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { IconComponent } from './components/icon.component';
import { SafeHTMLPipe } from './pipes/safe-inner-html.pipe';
import { LocaleDatePipe } from './pipes/locale-date.pipe';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { LoaderComponent } from './components/loader.component';
import { PrivacyConsentComponent } from './components/privacy-consent/privacy-consent.component';
import { CountrySelectorComponent } from './components/country-selector.component';
import { NgClickOutsideDirective } from 'ng-click-outside2';

const COMPONENTS: any[] = [
  IconComponent,
  SafeHTMLPipe,
  LocaleDatePipe,
  PhoneInputComponent,
  LoaderComponent,
  PrivacyConsentComponent,
  CountrySelectorComponent
];

const MODULES = [
  CommonModule,
  RouterModule,
  TranslateModule,
  MatIconModule,
  MatDialogModule,
  NgClickOutsideDirective
];

@NgModule({
  imports: [...MODULES],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS, ...MODULES]
})
export class SharedModule {}
