import { createAction, props } from '@ngrx/store';
import { Sender } from 'src/types';
import { Organization } from './organization.model';

enum OrganizationActionTypes {
  UpdateOrganization = '[Org] Update',
  UpdateOrganizationSuccess = '[Org] Update Success',
  UpdateOrganizationFailure = '[Org] Update Failure',
  LoadOrganizationSenders = '[Organization] Get Zelo Senders',
  LoadOrganizationSendersSuccess = '[Organization] Get Organization Senders success',
  LoadOrganizationSendersFailure = '[Organization] Get Organization Senders Failure',
  AddOrganizationSender = '[Organization] Add Organization sender',
  AddOrganizationSenderSuccess = '[Organization] Add Organization sender Success',
  AddOrganizationSenderFailure = '[Organization] Add Organization sender Failure',
  UpdateOrganizationSender = '[Organization] Update Organization sender',
  UpdateOrganizationSenderSuccess = '[Organization] Update Organization sender Success',
  UpdateOrganizationSenderFailure = '[Organization] Update Organization sender Failure',
  DeleteOrganizationSender = '[Organization] Delete Organization sender',
  DeleteOrganizationSenderSuccess = '[Organization] Delete Organization sender Success',
  DeleteOrganizationSenderFailure = '[Organization] Delete Organization sender Failure',
  LoadOrganizationEmailTemplates = '[Organization] Get Prganization email templates',
  LoadOrganizationEmailTemplatesSuccess = '[Organization] Get Prganization email templates success',
  LoadOrganizationEmailTemplatesFailure = '[Organization] Get Prganization email templates failure'
}

// Update Organization
export const updateOrganization = createAction(
  OrganizationActionTypes.UpdateOrganization,
  props<{ organization: Partial<Organization>; isAdmin?: boolean }>()
);

export const updateOrganizationSuccess = createAction(
  OrganizationActionTypes.UpdateOrganizationSuccess,
  props<Partial<Organization>>()
);

export const updateOrganizationFailure = createAction(
  OrganizationActionTypes.UpdateOrganizationFailure,
  props<any>()
);

// Load Organizaiton Senders
export const loadOrganizationSenders = createAction(
  OrganizationActionTypes.LoadOrganizationSenders
);

export const loadOrganizationSendersSuccess = createAction(
  OrganizationActionTypes.LoadOrganizationSendersSuccess,
  props<{ senders: { [key: string]: Sender } }>()
);

export const loadOrganizationSendersFailure = createAction(
  OrganizationActionTypes.LoadOrganizationSendersFailure,
  props<{ error: any }>()
);

// Add Organization Senders
export const addOrganizationSender = createAction(
  OrganizationActionTypes.AddOrganizationSender,
  props<{ sender: Sender; zeloId?: string }>()
);

export const addOrganizationSenderSuccess = createAction(
  OrganizationActionTypes.AddOrganizationSenderSuccess,
  props<{ sender: { [key: string]: Sender } }>()
);

export const addOrganizationSenderFailure = createAction(
  OrganizationActionTypes.AddOrganizationSenderFailure,
  props<{ error: any }>()
);

// Update Organization Senders
export const updateOrganizationSender = createAction(
  OrganizationActionTypes.UpdateOrganizationSender,
  props<{ sender: Sender; senderId: string }>()
);

export const updateOrganizationSenderSuccess = createAction(
  OrganizationActionTypes.UpdateOrganizationSenderSuccess,
  props<{ sender: { [key: string]: Sender } }>()
);

export const updateOrganizationSenderFailure = createAction(
  OrganizationActionTypes.UpdateOrganizationSenderFailure,
  props<{ error: any }>()
);

// Delete Organization Senders
export const deleteOrganizationSender = createAction(
  OrganizationActionTypes.DeleteOrganizationSender,
  props<{ senderId: string }>()
);

export const deleteOrganizationSenderSuccess = createAction(
  OrganizationActionTypes.DeleteOrganizationSenderSuccess,
  props<{ sender: { [key: string]: Sender } }>()
);

export const deleteOrganizationSenderFailure = createAction(
  OrganizationActionTypes.DeleteOrganizationSenderFailure,
  props<{ error: any }>()
);

// Get organization email templates
export const loadOrganizationEmailTemplates = createAction(
  OrganizationActionTypes.LoadOrganizationEmailTemplates
);

export const loadOrganizationEmailTemplatesSuccess = createAction(
  OrganizationActionTypes.LoadOrganizationEmailTemplatesSuccess,
  props<{ templates: [] }>()
);

export const loadOrganizationEmailTemplatesFailure = createAction(
  OrganizationActionTypes.LoadOrganizationEmailTemplatesFailure,
  props<{ error: any }>()
);
