<zlo-background>
  <div class="cell auto">
    <ng-container *ngIf="routerState$ | async as routerState">
      <zlo-login-form
        (submitted)="onSubmit($event, routerState.url)"
        [pending]="pending$ | async"
        *ngIf="routerState.url === '/auth/login'"
      >
      </zlo-login-form>

      <zlo-forgot-password
        (submitted)="onSubmit($event, routerState.url)"
        [pending]="pending$ | async"
        *ngIf="routerState.url === '/auth/forgot'"
      ></zlo-forgot-password>
    </ng-container>
  </div>
</zlo-background>
