import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as fromAuth from '../../auth/reducers';
import * as CryptoJS from 'crypto-js';
import { User } from '../../../types';
import { isTestUser } from '../../adminPortal/shared/util';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SegmentService implements OnDestroy {
  loggedInUser: User | null;
  subscription: Subscription = new Subscription();

  constructor(private store: Store<fromAuth.State>) {
    this.subscription.add(
      this.store.pipe(select(fromAuth.getUser)).subscribe((user: User) => {
        if (user) {
          this.loggedInUser = user;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  identifySegmentUser = (
    user: User = this.loggedInUser,
    options: { isSignupFlow: boolean; googleAnalytics: boolean } = {
      isSignupFlow: false,
      googleAnalytics: false
    }
  ) => {
    if (!user) return;
    if (isTestUser(user)) return;

    (<any>window).analytics.identify(
      user.id,
      {
        name: user.firstname + ' ' + user.lastname,
        email: user.email,
        isAdmin: user.roles?.isAdmin,
        isManager: user.roles?.isManager,
        createdAt: user.createdAt,
        language: user.organization?.language,
        department: user.departments[0]?.name,
        isSpamaUser: ['spama'].includes(user.organization.name.toLowerCase()),
        isProduction: environment.production,
        isInSingupFlow: options.isSignupFlow,
        company: {
          name: user.organization?.name,
          id: user.organization?.id,
          plan: user.organization?.tier,
          isProduction: environment.production
        }
      },
      {
        integrations: {
          Intercom: {
            user_hash: CryptoJS.enc.Hex.stringify(
              CryptoJS.HmacSHA256(user.id, environment.intercomVerificationKey)
            )
          },
          'Google Analytics': options.googleAnalytics
        }
      }
    );
    (<any>window).analytics.group(user.organization.id, {
      name: user.organization.name
    });
  };

  track = (
    event: string,
    properties: any = {},
    options: { googleAnalytics: boolean } = { googleAnalytics: false }
  ) => {
    if (!this.loggedInUser) return;
    if (isTestUser(this.loggedInUser)) return;

    (<any>window).analytics.track(event, properties, {
      integrations: {
        'Google Analytics': options.googleAnalytics
      }
    });
  };

  trackAnonymous = (
    event: string,
    properties: any = {},
    options: { googleAnalytics: boolean } = { googleAnalytics: false }
  ) => {
    (<any>window).analytics.track(event, properties, {
      integrations: {
        'Google Analytics': options.googleAnalytics
      }
    });
  };
}
