import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Authenticate } from 'src/types';
import { UserValidators } from '../user.util';

@Component({
  selector: 'zlo-login-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {
  @Input()
  set pending(isPending: boolean) {
    if (isPending) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Output()
  submitted = new EventEmitter<Authenticate>();

  form: UntypedFormGroup = new UntypedFormGroup(
    {
      email: new UntypedFormControl('', UserValidators.requiredEmail),
      password: new UntypedFormControl('', UserValidators.requiredPassword)
    },
    { updateOn: 'submit' }
  );

  submit() {
    if (this.form.valid) {
      this.form.value.email = this.form.value.email.trim();
      this.submitted.emit(this.form.value as Authenticate);
    }
  }
}
