import { Action } from '@ngrx/store';
import {
  OrganizationStatisticsResponse,
  User,
  Channel,
  OrganizationSettings,
  Whitelabel
} from 'src/types';

export enum LegacySettingsActionTypes {
  OrganizationStatistics = '[Settings] Organization Statistics',
  OrganizationStatisticsSuccess = '[Settings] Organization Statistics Success',
  OrganizationStatisticsFailure = '[Settings] Organization Statistics Failure',
  UpdateCurrentUser = '[Settings] Update CurrentUser',
  UpdateCurrentUserSuccess = '[Settings] Update Current User Success',
  UpdateCurrentUserFailure = '[Settings] Update Current User Failure',
  CreateIntegrationCredentials = '[Settings] Create Integration Credentials',
  CreateIntegrationCredentialsSuccess = '[Settings] Create Integration Credentials Success',
  CreateIntegrationCredentialsFailure = '[Settings] Create Integration Credentials Failure',
  UpdateIntegration = '[Settings] Update Integration ',
  UpdateIntegrationSuccess = '[Settings] Update Integration  Success',
  UpdateIntegrationFailure = '[Settings] Update Integration  Failure',
  UpdateOrganizationPreferences = '[Settings] Update Organization Preferences',
  UpdateOrganizationPreferencesSuccess = '[Settings] Update Organization Preferences Success',
  UpdateOrganizationPreferencesFailure = '[Settings] Update Organization Preferences Failure',
  LoadOrganizationWhitelabel = '[Settings] Load Organization Whitelabel',
  LoadOrganizationWhitelabelSuccess = '[Settings] Load Organization Whitelabel Success',
  LoadOrganizationWhitelabelFailure = '[Settings] Load Organization Whitelabel Failure'
}

export class OrganizationStatistics implements Action {
  readonly type = LegacySettingsActionTypes.OrganizationStatistics;
}

export class OrganizationStatisticsSuccess implements Action {
  readonly type = LegacySettingsActionTypes.OrganizationStatisticsSuccess;

  constructor(public payload: OrganizationStatisticsResponse) {}
}

export class OrganizationStatisticsFailure implements Action {
  readonly type = LegacySettingsActionTypes.OrganizationStatisticsFailure;

  constructor(public payload: any) {}
}

export class UpdateCurrentUser implements Action {
  readonly type = LegacySettingsActionTypes.UpdateCurrentUser;

  constructor(public payload: User) {}
}

export class UpdateCurrentUserSuccess implements Action {
  readonly type = LegacySettingsActionTypes.UpdateCurrentUserSuccess;

  constructor(public payload: User) {}
}
export class UpdateCurrentUserFailure implements Action {
  readonly type = LegacySettingsActionTypes.UpdateCurrentUserFailure;

  constructor(public payload: any) {}
}

export class CreateIntegrationCredentials implements Action {
  readonly type = LegacySettingsActionTypes.CreateIntegrationCredentials;

  constructor(
    public payload: {
      channel: Omit<Channel, 'id' | 'createdAt' | 'updatedAt'>;
      code?: string;
      organizationId: string;
    }
  ) {}
}

export class CreateIntegrationCredentialsSuccess implements Action {
  readonly type = LegacySettingsActionTypes.CreateIntegrationCredentialsSuccess;

  constructor(public payload: Channel[]) {}
}

export class CreateIntegrationCredentialsFailure implements Action {
  readonly type = LegacySettingsActionTypes.CreateIntegrationCredentialsFailure;

  constructor(public payload: any) {}
}

export class UpdateIntegration implements Action {
  readonly type = LegacySettingsActionTypes.UpdateIntegration;

  constructor(
    public payload: {
      updatedChannel: Partial<Channel>;
      organizationId: string;
    }
  ) {}
}

export class UpdateIntegrationSuccess implements Action {
  readonly type = LegacySettingsActionTypes.UpdateIntegrationSuccess;

  constructor(public payload: Channel[]) {}
}

export class UpdateIntegrationFailure implements Action {
  readonly type = LegacySettingsActionTypes.UpdateIntegrationFailure;

  constructor(public payload: any) {}
}

export class UpdateOrganizationPreferences implements Action {
  readonly type = LegacySettingsActionTypes.UpdateOrganizationPreferences;

  constructor(public payload: Partial<OrganizationSettings>) {}
}

export class UpdateOrganizationPreferencesSuccess implements Action {
  readonly type =
    LegacySettingsActionTypes.UpdateOrganizationPreferencesSuccess;

  constructor(public payload?: OrganizationSettings) {}
}

export class UpdateOrganizationPreferencesFailure implements Action {
  readonly type =
    LegacySettingsActionTypes.UpdateOrganizationPreferencesFailure;

  constructor(public payload: any) {}
}

export class LoadOrganizationWhitelabel implements Action {
  readonly type = LegacySettingsActionTypes.LoadOrganizationWhitelabel;
  constructor(public payload: { organizationId: string }) {}
}

export class LoadOrganizationWhitelabelSuccess implements Action {
  readonly type = LegacySettingsActionTypes.LoadOrganizationWhitelabelSuccess;

  constructor(public payload: Whitelabel) {}
}

export class LoadOrganizationWhitelabelFailure implements Action {
  readonly type = LegacySettingsActionTypes.LoadOrganizationWhitelabelFailure;

  constructor(public payload: any) {}
}

export type LegacySettingsActions =
  | OrganizationStatistics
  | OrganizationStatisticsSuccess
  | OrganizationStatisticsFailure
  | UpdateCurrentUser
  | UpdateCurrentUserSuccess
  | UpdateCurrentUserFailure
  | CreateIntegrationCredentials
  | CreateIntegrationCredentialsSuccess
  | CreateIntegrationCredentialsFailure
  | UpdateIntegration
  | UpdateIntegrationSuccess
  | UpdateIntegrationFailure
  | UpdateOrganizationPreferences
  | UpdateOrganizationPreferencesSuccess
  | UpdateOrganizationPreferencesFailure
  | LoadOrganizationWhitelabel
  | LoadOrganizationWhitelabelSuccess
  | LoadOrganizationWhitelabelFailure;
