import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { OverviewLink } from '../actions/auth.actions';
import { UserValidators } from '../user.util';

@Component({
  selector: 'zlo-overview-link-prompt',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './overview-link-prompt.component.html',
  styleUrls: ['./overview-link-prompt.component.scss']
})
export class OverviewLinkPromptComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private store: Store<any>) {}

  ngOnInit() {
    this.form = this.fb.group(
      {
        email: ['', UserValidators.requiredEmail]
      },
      { updateOn: 'submit' }
    );
  }

  submit() {
    if (this.form.valid) {
      this.store.dispatch(
        OverviewLink({ payload: this.form.get('email').value.trim() })
      );
    }
  }
}
